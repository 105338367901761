<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
  
   <v-row>
    <v-col class="mx-auto"
        cols="12"
        md="12">
       <v-card>
                <v-dialog
                  ref="SelDate"
                  v-model="menuSelDate"
                  :return-value.sync="txtDatein"
                  persistent
                  width="290px"
                >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="txtDatein"
                    label="Pilih Tanggal"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                  <v-date-picker 
                  v-model="txtDatein"
                  >
                  <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuSelDate = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.SelDate.save(txtDatein)">OK</v-btn>
                  </v-date-picker>
                  
              </v-dialog>
              </v-card>
    </v-col>
  </v-row>

   <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >

  <v-toolbar flat color="primary" dark>
      <v-toolbar-title>WEBSERVICE BIOS G2</v-toolbar-title>
    </v-toolbar>
    <v-tabs centered>
     
      <v-tab class="text-left" @click="tabClicked(1)">
        <v-icon left>mdi-lock</v-icon>
        Saldo BLU
      </v-tab>
      <v-tab class="text-left" @click="tabClicked(2)">
        <v-icon left>mdi-access-point</v-icon>
        Layanan Lainnya
      </v-tab>
       <v-tab class="text-left" @click="tabClicked(3)">
        <v-icon left>mdi-account</v-icon>
        Monitoring API
      </v-tab>
      <v-tab class="text-left" @click="tabClicked(4)">
        <v-icon left>mdi-account</v-icon>
        Data di Webservice BIOS
      </v-tab>

      
      <v-tab-item class="px-3">
        <v-card flat>
          <v-row>

            <v-col>
              <v-btn style="margin-top:15px" small color="blue" @click="checkSaldoBLU()">Show Data</v-btn>
            </v-col>
            
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-expansion-panels focusable>
                <v-expansion-panel key="1">
                  <v-expansion-panel-header><div>REKENING PENGELOLAAN KAS</div><div style="font-size:10px;" class="text-right font-italic">Status: <span v-if="modes1.respon != ''" >{{ modes1.tglUpdate }}, {{ modes1.respon }}</span><span v-else>-</span></div></v-expansion-panel-header>
                  <v-expansion-panel-content class="indigo darken-2">
                    
                      <base-material-card
                        icon="mdi-clipboard-text"
                        title="REK PENGELOLAAN KAS"
                        class="px-5 py-3"
                      >
                      <v-row>
                      <v-col
                          cols="6"
                          md="6"
                        >
                   
                        <v-form ref="forms1" v-model="modes1.s1.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s1.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s1.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s1.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                              
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s1.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s1.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s1.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,1)"
                            >
                              <span v-if="!modes1.s1.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
                        </v-form>
                 
                      </v-col>

                      <v-col
                          cols="6"
                          md="6"
                        >
                          <v-form ref="forms1" v-model="modes1.s2.valid">
                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-autocomplete
                                v-model="saldoForm1.s2.kd_bank"
                                :items="listKodeBank"
                                label="Pilih Bank"
                                item-text="uraian"
                                item-value="kode"
                                :rules="[rules.required]"
                                readonly
                              ></v-autocomplete>

                            </v-col>
                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="saldoForm1.s2.norek"
                                :rules="[rules.required, rules.numeric]"
                                label="No. Rekening"
                                readonly
                              ></v-text-field>  
                            </v-col>

                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="saldoForm1.s2.saldo"
                                :rules="[rules.required, rules.numeric]"
                                label="Saldo"
                              ></v-text-field>  
                              <div class="text-right">Rp. {{ saldoForm1.s2.saldo | formatMoney}}</div>
                            </v-col>

                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="saldoForm1.s2.kd_rek"
                                label="Kode Rekening"
                                readonly
                              ></v-text-field>  
                            </v-col>
                            <v-divider></v-divider>
                            <v-col>

                              <v-btn :disabled="!modes1.s2.valid" 
                                color="primary"
                                class="mr-4"
                                @click="saveAddFormS(1,2)"
                              >
                                <span v-if="!modes1.s2.isEdit">Simpan</span>
                                <span v-else>Ubah</span>
                              </v-btn>
                            
                            </v-col>
                          </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
                          <v-form ref="forms1" v-model="modes1.s3.valid">
                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-autocomplete
                                v-model="saldoForm1.s3.kd_bank"
                                :items="listKodeBank"
                                label="Pilih Bank"
                                item-text="uraian"
                                item-value="kode"
                                :rules="[rules.required]"
                                readonly
                              ></v-autocomplete>

                            </v-col>
                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="saldoForm1.s3.norek"
                                :rules="[rules.required, rules.numeric]"
                                label="No. Rekening"
                                readonly
                              ></v-text-field>  
                            </v-col>

                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="saldoForm1.s3.saldo"
                                :rules="[rules.required, rules.numeric]"
                                label="Saldo"
                              ></v-text-field>  
                              <div class="text-right">Rp. {{ saldoForm1.s3.saldo | formatMoney}}</div>
                            </v-col>

                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="saldoForm1.s3.kd_rek"
                                label="Kode Rekening"
                                readonly
                              ></v-text-field>  
                            </v-col>
                            <v-divider></v-divider>
                            <v-col>

                              <v-btn :disabled="!modes1.s3.valid" 
                                color="primary"
                                class="mr-4"
                                @click="saveAddFormS(1,3)"
                              >
                                <span v-if="!modes1.s3.isEdit">Simpan</span>
                                <span v-else>Ubah</span>
                              </v-btn>
                            
                            </v-col>
                          </v-form>
              
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s4.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s4.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s4.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s4.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s4.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s4.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s4.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,4)"
                            >
                              <span v-if="!modes1.s4.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
              
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s5.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s5.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s5.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s5.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s5.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s5.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s5.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,5)"
                            >
                              <span v-if="!modes1.s5.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s6.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s6.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s6.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s6.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s6.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s6.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s6.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,6)"
                            >
                              <span v-if="!modes1.s6.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s7.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s7.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s7.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s7.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s7.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s7.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s7.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,7)"
                            >
                              <span v-if="!modes1.s7.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s8.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s8.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s8.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s8.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s8.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s8.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s8.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,8)"
                            >
                              <span v-if="!modes1.s8.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s9.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s9.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s9.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s9.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s9.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s9.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s9.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,9)"
                            >
                              <span v-if="!modes1.s9.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s10.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s10.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s10.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s10.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s10.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s10.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s10.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,10)"
                            >
                              <span v-if="!modes1.s10.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s11.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s11.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s11.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s11.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s11.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s11.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s11.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,11)"
                            >
                              <span v-if="!modes1.s11.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s12.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s12.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s12.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s12.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s12.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s12.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s12.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,12)"
                            >
                              <span v-if="!modes1.s12.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s13.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s13.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s13.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s13.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s13.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s13.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s13.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,13)"
                            >
                              <span v-if="!modes1.s13.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s14.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s14.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s14.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s14.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s14.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s14.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s14.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,14)"
                            >
                              <span v-if="!modes1.s14.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s15.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s15.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s15.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s15.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s15.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s15.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s15.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,15)"
                            >
                              <span v-if="!modes1.s15.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes1.s16.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm1.s16.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s16.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s16.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm1.s16.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm1.s16.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes1.s16.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(1,16)"
                            >
                              <span v-if="!modes1.s16.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
                        </v-col>
                      </v-row>
                      </base-material-card>
              
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel key="2">
                  <v-expansion-panel-header><div>REKENING OPERASIONAL BLU</div><div style="font-size:10px;" class="text-right font-italic">Status: <span v-if="modes2.respon != ''" >{{ modes2.tglUpdate }}, {{ modes2.respon }}</span><span v-else>-</span></div></v-expansion-panel-header>
                  <v-expansion-panel-content class="red darken-2">
                    
                    <base-material-card
                      icon="mdi-clipboard-text"
                      title="REK OPERASIONAL BLU"
                      class="px-5 py-3"
                    >
                    <v-row>
                      <v-col
                          cols="6"
                          md="6"
                        >
                   
                        <v-form ref="forms1" v-model="modes2.s1.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm2.s1.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm2.s1.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm2.s1.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                              
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm2.s1.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm2.s1.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes2.s1.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(2,1)"
                            >
                              <span v-if="!modes2.s1.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
                        </v-form>
                 
                      </v-col>

                      <v-col
                          cols="6"
                          md="6"
                        >
                          <v-form ref="forms1" v-model="modes2.s2.valid">
                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-autocomplete
                                v-model="saldoForm2.s2.kd_bank"
                                :items="listKodeBank"
                                label="Pilih Bank"
                                item-text="uraian"
                                item-value="kode"
                                :rules="[rules.required]"
                                readonly
                              ></v-autocomplete>

                            </v-col>
                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="saldoForm2.s2.norek"
                                :rules="[rules.required, rules.numeric]"
                                label="No. Rekening"
                                readonly
                              ></v-text-field>  
                            </v-col>

                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="saldoForm2.s2.saldo"
                                :rules="[rules.required, rules.numeric]"
                                label="Saldo"
                              ></v-text-field>  
                              <div class="text-right">Rp. {{ saldoForm2.s2.saldo | formatMoney}}</div>
                            </v-col>

                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="saldoForm2.s2.kd_rek"
                                label="Kode Rekening"
                                readonly
                              ></v-text-field>  
                            </v-col>
                            <v-divider></v-divider>
                            <v-col>

                              <v-btn :disabled="!modes2.s2.valid" 
                                color="primary"
                                class="mr-4"
                                @click="saveAddFormS(2,2)"
                              >
                                <span v-if="!modes2.s2.isEdit">Simpan</span>
                                <span v-else>Ubah</span>
                              </v-btn>
                            
                            </v-col>
                          </v-form>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
                          <v-form ref="forms1" v-model="modes2.s3.valid">
                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-autocomplete
                                v-model="saldoForm2.s3.kd_bank"
                                :items="listKodeBank"
                                label="Pilih Bank"
                                item-text="uraian"
                                item-value="kode"
                                :rules="[rules.required]"
                                readonly
                              ></v-autocomplete>

                            </v-col>
                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="saldoForm2.s3.norek"
                                :rules="[rules.required, rules.numeric]"
                                label="No. Rekening"
                                readonly
                              ></v-text-field>  
                            </v-col>

                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="saldoForm2.s3.saldo"
                                :rules="[rules.required, rules.numeric]"
                                label="Saldo"
                              ></v-text-field>  
                              <div class="text-right">Rp. {{ saldoForm2.s3.saldo | formatMoney}}</div>
                            </v-col>

                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="saldoForm2.s3.kd_rek"
                                label="Kode Rekening"
                                readonly
                              ></v-text-field>  
                            </v-col>
                            <v-divider></v-divider>
                            <v-col>

                              <v-btn :disabled="!modes2.s3.valid" 
                                color="primary"
                                class="mr-4"
                                @click="saveAddFormS(2,3)"
                              >
                                <span v-if="!modes2.s3.isEdit">Simpan</span>
                                <span v-else>Ubah</span>
                              </v-btn>
                            
                            </v-col>
                          </v-form>
              
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                        >
 <v-form ref="forms1" v-model="modes2.s4.valid">
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-autocomplete
                              v-model="saldoForm2.s4.kd_bank"
                              :items="listKodeBank"
                              label="Pilih Bank"
                              item-text="uraian"
                              item-value="kode"
                              :rules="[rules.required]"
                              readonly
                            ></v-autocomplete>

                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm2.s4.norek"
                              :rules="[rules.required, rules.numeric]"
                              label="No. Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm2.s4.saldo"
                              :rules="[rules.required, rules.numeric]"
                              label="Saldo"
                            ></v-text-field>  
                            <div class="text-right">Rp. {{ saldoForm2.s4.saldo | formatMoney}}</div>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="saldoForm2.s4.kd_rek"
                              label="Kode Rekening"
                              readonly
                            ></v-text-field>  
                          </v-col>
                          <v-divider></v-divider>
                          <v-col>

                            <v-btn :disabled="!modes2.s4.valid" 
                              color="primary"
                              class="mr-4"
                              @click="saveAddFormS(2,4)"
                            >
                              <span v-if="!modes2.s4.isEdit">Simpan</span>
                              <span v-else>Ubah</span>
                            </v-btn>
                           
                          </v-col>
 </v-form>
              
                        
                        </v-col>
                    </v-row>
                    </base-material-card> 
            
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel key="3">
                  <v-expansion-panel-header><div>REKENING DANA KELOLAAN</div><div style="font-size:10px;" class="text-right font-italic">Status: <span v-if="modes3.respon != ''" >{{ modes3.tglUpdate }}, {{ modes3.respon }}</span><span v-else>-</span></div></v-expansion-panel-header>
                  <v-expansion-panel-content class="orange darken-2">
                    <v-form ref="forms3" v-model="modes3.valid">
                    <base-material-card
                      icon="mdi-clipboard-text"
                      title="REK. DANA KELOLAAN"
                      class="px-5 py-3"
                    >
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-autocomplete
                          v-model="saldoForm3.kd_bank"
                          :items="listKodeBank"
                          label="Pilih Bank"
                          item-text="uraian"
                          item-value="kode"
                          :rules="[rules.required]"
                        ></v-autocomplete>

                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="saldoForm3.norek"
                          :rules="[rules.required, rules.numeric]"
                          label="No. Rekening"
                        ></v-text-field>  
                      </v-col>

                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="saldoForm3.saldo"
                          :rules="[rules.required, rules.numeric]"
                          label="Saldo"
                        ></v-text-field>  
                        <div class="text-right">Rp. {{ saldoForm3.saldo | formatMoney}}</div>
                      </v-col>

                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="saldoForm3.kd_rek"
                          label="Kode Rekening"
                          readonly
                        ></v-text-field>  
                      </v-col>

                      <v-divider></v-divider>
                        <v-col>

                          <v-btn :disabled="!modes3.valid" 
                            color="primary"
                            class="mr-4"
                            @click="saveAddFormS(3,0)"
                          >
                            <span v-if="!modes3.isEdit">Simpan</span>
                            <span v-else>Ubah</span>
                          </v-btn>
                          <v-btn  :disabled="!modes3.canSend" 
                            color="red"
                            class="mr-4"
                            @click="doSendSaldo(3)"
                          >
                            Kirim BIOS
                          </v-btn>
                    
                        </v-col>
                      
                    </base-material-card>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            
            </v-col>
          </v-row>
          
        </v-card>
      </v-tab-item>
      <v-tab-item class="px-3">
        <v-card flat>
          <v-row>
            <v-col>
              <v-btn style="margin-top:15px" small color="blue" @click="checkLayananLain()">Show Data</v-btn>
            </v-col>

          </v-row>

          <v-row>
            <v-col cols="12">
              <v-expansion-panels focusable>
                <v-expansion-panel key="1">
                  <v-expansion-panel-header><div>30001 - Jumlah Realisasi penyaluran dana kelolaan (Rp.)</div><div style="font-size:10px;" class="text-right font-italic">Status: <span v-if="model1.respon != ''" >{{ model1.tglUpdate }}, {{ model1.respon }}</span><span v-else>-</span></div></v-expansion-panel-header>
                  <v-expansion-panel-content class="indigo darken-2">
                    <v-form ref="forml1" v-model="model1.valid">
                      <base-material-card
                        icon="mdi-clipboard-text"
                        title="30001"
                        class="px-5 py-3"
                      >
                        <v-col
                        cols="12"
                        md="12"
                      >
                        <v-autocomplete
                        readonly
                          v-model="layananForm1.kd_indikator"
                          :items="listKodeIndikator"
                          label="Pilih Kode Indikator"
                          item-text="uraian"
                          item-value="kode"
            
                          :rules="[rules.required]"
                        ></v-autocomplete>

                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="layananForm1.jumlah"
                          :rules="[rules.required, rules.numeric]"
                          label="Jumlah"
                        ></v-text-field>  
                        <div class="text-right">Rp. {{ layananForm1.jumlah | formatMoney}}</div>
                      </v-col>

                      <v-divider></v-divider>
                        <v-col>

                          <v-btn :disabled="!model1.valid" 
                            color="primary"
                            class="mr-4"
                            @click="saveAddFormL(1)"
                          >
                            <span v-if="!model1.isEdit">Simpan</span>
                            <span v-else>Ubah</span>
                          </v-btn>
                          <v-btn :disabled="!model1.canSend" 
                            color="red"
                            class="mr-4"
                            @click="doSendLayanan(1)"
                          >
                            Kirim BIOS
                          </v-btn>
                    
                        </v-col>
                        
                      </base-material-card>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel key="2">
                  <v-expansion-panel-header><div>30002 - Harga CPO (USD)</div><div style="font-size:10px;" class="text-right font-italic">Status: <span v-if="model2.respon != ''" >{{ model2.tglUpdate }}, {{ model2.respon }}</span><span v-else>-</span></div></v-expansion-panel-header>
                  <v-expansion-panel-content class="red darken-2">
                    <v-form ref="forml2" v-model="model2.valid">
                    <base-material-card
                      icon="mdi-clipboard-text"
                      title="30002"
                      class="px-5 py-3"
                    >
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-autocomplete
                        readonly
                          v-model="layananForm2.kd_indikator"
                          :items="listKodeIndikator"
                          label="Pilih Kode Indikator"
                          item-text="uraian"
                          item-value="kode"
            
                          :rules="[rules.required]"
                        ></v-autocomplete>

                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="layananForm2.jumlah"
                          :rules="[rules.required, rules.numeric]"
                          label="Jumlah"
                        ></v-text-field>  
                        <div class="text-right">$. {{ layananForm2.jumlah | formatMoney}}</div>
                      </v-col>

                      <v-divider></v-divider>
                        <v-col>

                          <v-btn :disabled="!model2.valid" 
                            color="primary"
                            class="mr-4"
                            @click="saveAddFormL(2)"
                          >
                            <span v-if="!model2.isEdit">Simpan</span>
                            <span v-else>Ubah</span>
                          </v-btn>
                          <v-btn :disabled="!model2.canSend" 
                            color="red"
                            class="mr-4"
                            @click="doSendLayanan(2)"
                          >
                            Kirim BIOS
                          </v-btn>
                    
                        </v-col>
                      
                    </base-material-card> 
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel key="3">
                  <v-expansion-panel-header><div>30003 - program replanting yang diselesaikan (Ha)</div><div style="font-size:10px;" class="text-right font-italic">Status: <span v-if="model3.respon != ''" >{{ model3.tglUpdate }}, {{ model3.respon }}</span><span v-else>-</span></div></v-expansion-panel-header>
                  <v-expansion-panel-content class="orange darken-2">
                    <v-form ref="forml3" v-model="model3.valid">
                    <base-material-card
                      icon="mdi-clipboard-text"
                      title="30003"
                      class="px-5 py-3"
                    >
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-autocomplete
                        readonly
                          v-model="layananForm3.kd_indikator"
                          :items="listKodeIndikator"
                          label="Pilih Kode Indikator"
                          item-text="uraian"
                          item-value="kode"
            
                          :rules="[rules.required]"
                        ></v-autocomplete>

                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="layananForm3.jumlah"
                          :rules="[rules.required, rules.numeric]"
                          label="Jumlah"
                        ></v-text-field>  
                        
                      </v-col>

                      <v-divider></v-divider>
                        <v-col>

                          <v-btn :disabled="!model3.valid" 
                            color="primary"
                            class="mr-4"
                            @click="saveAddFormL(3)"
                          >
                            <span v-if="!model3.isEdit">Simpan</span>
                            <span v-else>Ubah</span>
                          </v-btn>
                    
                          <v-btn  :disabled="!model3.canSend" 
                            color="red"
                            class="mr-4"
                            @click="doSendLayanan(3)"
                          >
                            Kirim BIOS
                          </v-btn>
                    
                        </v-col>
                      
                    </base-material-card>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel key="4">
                  <v-expansion-panel-header><div>30004 - Jumlah Riset yang Dibiayai (unit)</div><div style="font-size:10px;" class="text-right font-italic">Status: <span v-if="model4.respon != ''" >{{ model4.tglUpdate }}, {{ model4.respon }}</span><span v-else>-</span></div></v-expansion-panel-header>
                  <v-expansion-panel-content class="green darken-2">
                    <v-form ref="forml4" v-model="model4.valid">
                    <base-material-card
                      icon="mdi-clipboard-text"
                      title="30004"
                      class="px-5 py-3"
                    >
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-autocomplete
                        readonly
                          v-model="layananForm4.kd_indikator"
                          :items="listKodeIndikator"
                          label="Pilih Kode Indikator"
                          item-text="uraian"
                          item-value="kode"
            
                          :rules="[rules.required]"
                        ></v-autocomplete>

                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="layananForm4.jumlah"
                          :rules="[rules.required, rules.numeric]"
                          label="Jumlah"
                        ></v-text-field>  
                        <!--<div class="text-right">{{ layananForm4.jumlah | formatMoney}}</div>-->
                      </v-col>
                      
                      <v-divider></v-divider>
                        <v-col>

                          <v-btn :disabled="!model4.valid" 
                            color="primary"
                            class="mr-4"
                            @click="saveAddFormL(4)"
                          >
                            <span v-if="!model4.isEdit">Simpan</span>
                            <span v-else>Ubah</span>
                          </v-btn>
                          <v-btn  :disabled="!model4.canSend" 
                            color="red"
                            class="mr-4"
                            @click="doSendLayanan(4)"
                          >
                            Kirim BIOS
                          </v-btn>
                    
                        </v-col>

                    </base-material-card>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel key="5">
                  <v-expansion-panel-header><div>30005 - Volume Biodiesel yang Disubsidi (Kilo Liter)</div> <div style="font-size:10px;" class="text-right font-italic">Status: <span v-if="model5.respon != ''" >{{ model5.tglUpdate }}, {{ model5.respon }}</span><span v-else>-</span></div></v-expansion-panel-header>
                  <v-expansion-panel-content class="purple darken-2">
                    <v-form ref="forml5" v-model="model5.valid">
                    <base-material-card
                      icon="mdi-clipboard-text"
                      title="30005"
                      class="px-5 py-3"
                    >
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-autocomplete
                        readonly
                          v-model="layananForm5.kd_indikator"
                          :items="listKodeIndikator"
                          label="Pilih Kode Indikator"
                          item-text="uraian"
                          item-value="kode"
            
                          :rules="[rules.required]"
                        ></v-autocomplete>

                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="layananForm5.jumlah"
                          :rules="[rules.required, rules.numeric]"
                          label="Jumlah"
                        ></v-text-field>  
                        <div class="text-right">{{ layananForm5.jumlah | formatMoney}}</div>
                      </v-col>

                      <v-divider></v-divider>
                        <v-col>

                          <v-btn :disabled="!model5.valid" 
                            color="primary"
                            class="mr-4"
                            @click="saveAddFormL(5)"
                          >
                            <span v-if="!model5.isEdit">Simpan</span>
                            <span v-else>Ubah</span>
                          </v-btn>
                          <v-btn  :disabled="!model5.canSend" 
                            color="red"
                            class="mr-4"
                            @click="doSendLayanan(5)"
                          >
                            Kirim BIOS
                          </v-btn>
                    
                        </v-col>
                      
                    </base-material-card>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>
            </v-col>
          </v-row>

        </v-card>
      </v-tab-item>
      <v-tab-item class="px-3">
        <v-card flat>
           <v-row>
           
            <v-col>
              <v-btn style="margin-top:15px" small color="blue" @click="getListMonitoring()">Show Data</v-btn>
            </v-col>
            
          </v-row>
          

          <v-data-table
              :headers="headers"
              :items="listData"
              class="elevation-1"
              :loading="listLoading"
            >
            <template v-slot:item.data="{ item }">
              <json-viewer style="font-size:10px"
                copyable
                boxed
                sort :value="item.jsond"></json-viewer>
            </template>

            <template v-slot:item.responsedata="{ item }">
              <json-viewer style="font-size:10px"
                copyable
                boxed
                sort :value="item.jsonrd"></json-viewer>
            </template>

            <template v-slot:item.tgl="{ item }">
              <span>{{ item.tgl | formatDate}}</span>
            </template>

          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item class="px-3">
        <v-card flat>
           <v-row>
           
            <v-col>
              <v-btn style="margin-top:15px" small color="blue" @click="getDataWSBios()">Show Data</v-btn>
            </v-col>
            
          </v-row>

            <v-row>
              <v-col cols="12">
                <v-card
                  class="mx-auto"
                >
                  <v-card-text>
                    <div class="subtitle-1 font-weight-bold text-center">Data Penerimaan di Server BIOS G2</div>

                    <v-data-table
                      :headers="headersws1"
                      :items="dataWS.penerimaan"
                      class="elevation-1"
                      :items-per-page="10"
                    >
                      <template v-slot:item.jumlah="{ item }">
                        <div style="min-width: 200px;" class="text-right">Rp. {{ item.jumlah | formatMoney }}</div>
                      </template>
                      <template v-slot:item.tgl_transaksi="{ item }">
                        <div>{{ item.tgl_transaksi | formatDate }}</div>
                      </template>
                    
                    </v-data-table>
                </v-card-text>
                
              </v-card>
              </v-col>

              <v-col cols="12">
                <v-card
                    class="mx-auto"
                  >
                    <v-card-text>
                    <div class="subtitle-1 font-weight-bold text-center">Data Pengeluaran di Server BIOS G2</div>
                    <v-data-table
                      :headers="headersws1"
                      :items="dataWS.pengeluaran"
                      class="elevation-1"
                      :items-per-page="10"
                    >
                        <template v-slot:item.jumlah="{ item }">
                          <div style="min-width: 200px;" class="text-right">Rp. {{ item.jumlah | formatMoney }}</div>
                        </template>
                        <template v-slot:item.tgl_transaksi="{ item }">
                          <div>{{ item.tgl_transaksi | formatDate }}</div>
                        </template>
                    </v-data-table>
                </v-card-text>
                  
                </v-card>
              </v-col>

            <v-col cols="12">
                <v-card
                    class="mx-auto"
                  >

                     <v-card-text>
                    <div class="subtitle-1 font-weight-bold text-center">Data Saldo di Server BIOS G2</div>

                    <v-data-table
                      :headers="headerswsaldo"
                      :items="dataWS.saldo"
                      class="elevation-1"
                      :items-per-page="10"
                    >
                    <template v-slot:item.saldo="{ item }">
                        <div style="min-width: 200px;" class="text-right">Rp. {{ item.saldo | formatMoney }}</div>
                      </template>
                      <template v-slot:item.tgl_transaksi="{ item }">
                        <div>{{ item.tgl_transaksi | formatDate }}</div>
                      </template>
                    </v-data-table>
                </v-card-text>
                  
                </v-card>
            </v-col>

            <v-col cols="12">
              <v-card
                  class="mx-auto"
                >

                 <v-card-text>
                    <div class="subtitle-1 font-weight-bold text-center">Data Layanan Lain di Server BIOS G2</div>
 
                    <v-data-table
                      :headers="headerswlay"
                      :items="dataWS.layanan"
                      class="elevation-1"
                      :items-per-page="10"
                    >
                    <template v-slot:item.jumlah="{ item }">
                        <div style="min-width: 200px;" class="text-right">Rp. {{ item.jumlah | formatMoney }}</div>
                      </template>
                      <template v-slot:item.tgl_transaksi="{ item }">
                        <div>{{ item.tgl_transaksi | formatDate }}</div>
                      </template>
                    </v-data-table>
                </v-card-text>
                
              </v-card>
            </v-col>
            </v-row>
            

        </v-card>
      </v-tab-item>
      
    </v-tabs>

      </v-col>
   </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
          <v-layout justify-center align-center>
            <v-card
              width="70vW"
              class="pa-md-2 mx-lg-auto"
              outlined
            >
              <v-list-item three-line centered>
                <v-list-item-content>
                  <div class="overline mb-4">Processing</div>
                  <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-progress-linear
                      indeterminate
                      color="yellow darken-2"
                    ></v-progress-linear>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>

          </v-layout>
      </v-container>
    </v-dialog>

  </v-container>
</template>

<script>
  import secureStorage from '@/utils/secureStorage'
  import moment from 'moment'
  import { getTRXBIOS, countTRXBIOS, getKodeBank, getKodeIndikator, insertSaldoBLU, updateSaldoBLU, insertLayananLain, updateLayananLain, getSaldoBLU, getLayananLain, getTrxLaporan, sendPenerimaan, sendBeban, sendSaldoBLU, sendLayananLain, getPenerimaanFromWSBios, getPengeluaranFromWSBios, getLayananLainFromWSBios, getSaldoFromWSBios, refreshTokenBIOSG2 } from '@/api/laporanbios'
  import formatCurrency from 'format-currency'
  import { _GLOBAL } from '@/api/server'


const saldobluForm = {
  kd_bank:'',
  norek: '',
  saldo: 0,
  kd_rek: '',
  tgl_transaksi: '',
}

const layananbluForm = {
  kd_indikator:'',
  jumlah: 0,
  tgl_transaksi: '',
}

  export default {
    name: 'BiosList',

    data: () => ({
      saldoForm1: {
        s1: Object.assign({}, saldobluForm),
        s2: Object.assign({}, saldobluForm),
        s3: Object.assign({}, saldobluForm),
        s4: Object.assign({}, saldobluForm),
        s5: Object.assign({}, saldobluForm),
        s6: Object.assign({}, saldobluForm),
        s7: Object.assign({}, saldobluForm),
        s8: Object.assign({}, saldobluForm),
        s9: Object.assign({}, saldobluForm),
        s10: Object.assign({}, saldobluForm),
        s11: Object.assign({}, saldobluForm),
        s12: Object.assign({}, saldobluForm),
        s13: Object.assign({}, saldobluForm),
        s14: Object.assign({}, saldobluForm),
        s15: Object.assign({}, saldobluForm),
        s16: Object.assign({}, saldobluForm),
      },
      saldoForm2: {
        s1: Object.assign({}, saldobluForm),
        s2: Object.assign({}, saldobluForm),
        s3: Object.assign({}, saldobluForm),
        s4: Object.assign({}, saldobluForm)
      },
      saldoForm3: Object.assign({}, saldobluForm),
      layananForm1: Object.assign({}, layananbluForm),
      layananForm2: Object.assign({}, layananbluForm),
      layananForm3: Object.assign({}, layananbluForm),
      layananForm4: Object.assign({}, layananbluForm),
      layananForm5: Object.assign({}, layananbluForm),
      rules: {
        required: v => !!v || 'Required.',
        numeric: v  => {
                  if (parseFloat(v)) return true;
                  return 'Must number';
                }
      },
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      items: [
        { title: 'Monitoring', icon: 'mdi-account' , link: '/finance/bios/list' },
          { title: 'Saldo BLU', icon: 'mdi-home-city', link: '/finance/bios/saldo' },
          { title: 'Layanan Lainnya', icon: 'mdi-account' , link: '/finance/bios/layananlain' },
        ],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'Data', value: 'data', align: 'center', class:'subtitle-1' },
        { text: 'Response Status', value: 'responsestatus', align: 'center', class:'subtitle-1' },
        { text: 'Response Data', value: 'responsedata', align: 'center', class:'subtitle-1' },
        { text: 'Tanggal', value: 'tgl', align: 'center', sortable: true, class:'subtitle-1' }
      ],
      isLoading: false,
      menuSelYear: false,
      listData: [],
      totalData: 0,
      listLoading: false,
      isTableMonitoringShow: false,
      tableOpt: {},
      listQuery: {
        page: 1,
        limit: 5,
        search: ''
      },
      menuSelDate: false,
      txtDatein: moment().format('YYYY-MM-DD'),
      listKodeBank: [],
      listKodeIndikator: [],
      listTrxLog: [],
      modes1 : {
        s1: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s2: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s3: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s4: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s5: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s6: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s7: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s8: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s9: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s10: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s11: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s12: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s13: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s14: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s15: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s16: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
      },
      modes2 : { 
        s1: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s2: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s3: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        s4: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
      },
      modes3 : { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
      
      model1 : { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
      model2 : { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
      model3 : { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
      model4 : { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
      model5 : { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
      dataWS: {
        penerimaan: [],
        pengeluaran: [],
        saldo: [],
        layanan: []
      },
      headersws1: [
        { text: 'Satker', value: 'kdsatker', align: 'center', class:'subtitle-1' },
        { text: 'Kode Akun', value: 'kd_akun', align: 'center', sortable: true,  class:'subtitle-1' },
        { text: 'Nama Akun', value: 'nm_akun', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Jumlah', value: 'jumlah', align: 'center', class:'subtitle-1'},
        { text: 'Tgl. Transaksi', value: 'tgl_transaksi', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Tgl. Update', value: 'tgl_update', align: 'center', sortable: true, class:'subtitle-1' }
      ],
      headerswsaldo: [
        { text: 'Satker', value: 'kdsatker', align: 'center', class:'subtitle-1' },
        { text: 'Kode Rekening', value: 'kd_rek', align: 'center', sortable: true,  class:'subtitle-1' },
        { text: 'Nama Rekening', value: 'nm_rek', align: 'center', sortable: true,  class:'subtitle-1' },
        { text: 'Kode Bank', value: 'kd_bank', align: 'center', sortable: true,  class:'subtitle-1' },
        { text: 'Nama Bank', value: 'nm_bank', align: 'center', sortable: true,  class:'subtitle-1' },
        { text: 'No. Rekening', value: 'norek', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Saldo', value: 'saldo', align: 'center', class:'subtitle-1' },
        { text: 'Tgl. Transaksi', value: 'tgl_transaksi', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Tgl. Update', value: 'tgl_update', align: 'center', sortable: true, class:'subtitle-1' }
      ],
      headerswlay: [
        { text: 'Satker', value: 'kdsatker', align: 'center', class:'subtitle-1' },
        { text: 'Kode Indikator', value: 'kd_indikator', align: 'center', sortable: true,  class:'subtitle-1' },
        { text: 'Nama Indikator', value: 'nm_indikator', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Jumlah', value: 'jumlah', align: 'center', class:'subtitle-1' },
        { text: 'Tgl. Transaksi', value: 'tgl_transaksi', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Tgl. Update', value: 'tgl_update', align: 'center', sortable: true, class:'subtitle-1' }
      ],
    }),
    filters: {
      formatDate: function (value) {
        if (!value) return '-'
        var sdate = moment(value).format('DD MMM YYYY')
        return sdate
      },
      formatMoney: function (value) {
        return formatCurrency(value)
      }
    },
    computed: {
    },
    created () {
      const vm = this

      vm.dataUser = secureStorage.getItem('user')
      if (vm.dataUser !== null) {
        // console.log(vm.dataUser)
        if(vm.dataUser.role !== null) {
          if(vm.dataUser.role.id === 1) {
            vm.isAdmin = true
          }
        }
      }
    },
    destroyed () {
    },
    mounted () {
      const vm = this

      vm.initKodeBank()
      vm.initKodeIndikator()
      vm.initTrxLaporan()
      
      vm.checkSaldoBLU()  
      vm.checkLayananLain()

    },
    watch: {
      tableOpt: {
        handler () {
          this.getListMonitoring()
        },
        deep: true,
      },
    },
    methods: {
      goBack(){
        this.$router.go(-1)
      },
      goFileLink (uri) {
        this.$router.push(uri);
      },
      tabClicked(idtab){
        this.isTableMonitoringShow = false
        // console.log('tabClicked ', idtab)
        this.initTrxLaporan()

        if(idtab == 1){
          this.checkSaldoBLU()
        }else if (idtab == 2){
          this.checkLayananLain()
        }else if(idtab == 3){
          this.getListMonitoring()
          this.isTableMonitoringShow = true
        }
      },
      resetFormSaldo () {
        this.saldoForm1= {
          s1: Object.assign({}, saldobluForm),
          s2: Object.assign({}, saldobluForm),
          s3: Object.assign({}, saldobluForm),
          s4: Object.assign({}, saldobluForm),
          s5: Object.assign({}, saldobluForm),
          s6: Object.assign({}, saldobluForm),
          s7: Object.assign({}, saldobluForm),
          s8: Object.assign({}, saldobluForm),
          s9: Object.assign({}, saldobluForm),
          s10: Object.assign({}, saldobluForm),
          s11: Object.assign({}, saldobluForm),
          s12: Object.assign({}, saldobluForm),
          s13: Object.assign({}, saldobluForm),
          s14: Object.assign({}, saldobluForm),
          s15: Object.assign({}, saldobluForm),
          s16: Object.assign({}, saldobluForm),
        }
        this.saldoForm2= {
          s1: Object.assign({}, saldobluForm),
          s2: Object.assign({}, saldobluForm),
          s3: Object.assign({}, saldobluForm),
          s4: Object.assign({}, saldobluForm)
        }

        this.saldoForm3= Object.assign({}, saldobluForm)

        this.modes1 = {
          s1: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s2: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s3: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s4: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s5: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s6: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s7: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s8: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s9: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s10: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s11: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s12: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s13: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s14: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s15: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s16: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        }

        this.modes2 = { 
          s1: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s2: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s3: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
          s4: { valid: false, isEdit: false, canSend: false , idData: 0, respon: '', tglUpdate: ''},
        }

        this.modes3 = { valid: false, isEdit: false, canSend: true , idData: 0, respon: '', tglUpdate: ''}

        //01
        this.saldoForm1.s1['kd_bank'] = '009' // BNI
        this.saldoForm1.s1['norek'] = '1407202001' 
        this.saldoForm1.s1['kd_rek'] = '01' 

        this.saldoForm1.s2['kd_bank'] = '009' // BNI
        this.saldoForm1.s2['norek'] = '0979293061' 
        this.saldoForm1.s2['kd_rek'] = '01' 

        this.saldoForm1.s3['kd_bank'] = '009' // BNI
        this.saldoForm1.s3['norek'] = '0986312859' 
        this.saldoForm1.s3['kd_rek'] = '01' 

        this.saldoForm1.s4['kd_bank'] = '009' // BNI
        this.saldoForm1.s4['norek'] = '1043581215' 
        this.saldoForm1.s4['kd_rek'] = '01' 

        this.saldoForm1.s5['kd_bank'] = '009' // BNI
        this.saldoForm1.s5['norek'] = '1044325410' 
        this.saldoForm1.s5['kd_rek'] = '01' 

        this.saldoForm1.s6['kd_bank'] = '009' // BNI
        this.saldoForm1.s6['norek'] = '2508202087' 
        this.saldoForm1.s6['kd_rek'] = '01' 

        this.saldoForm1.s7['kd_bank'] = '009' // BNI
        this.saldoForm1.s7['norek'] = '2608202006' 
        this.saldoForm1.s7['kd_rek'] = '01' 

        this.saldoForm1.s8['kd_bank'] = '008' // BMRI
        this.saldoForm1.s8['norek'] = '1220205471421' 
        this.saldoForm1.s8['kd_rek'] = '01' 

        this.saldoForm1.s9['kd_bank'] = '002' // BRI
        this.saldoForm1.s9['norek'] = '032901040532409' 
        this.saldoForm1.s9['kd_rek'] = '01' 

        this.saldoForm1.s10['kd_bank'] = '002' // BRI
        this.saldoForm1.s10['norek'] = '032901042606408' 
        this.saldoForm1.s10['kd_rek'] = '01' 

        this.saldoForm1.s11['kd_bank'] = '002' // BRI
        this.saldoForm1.s11['norek'] = '032901042607404' 
        this.saldoForm1.s11['kd_rek'] = '01' 

        this.saldoForm1.s12['kd_bank'] = '002' // BRI
        this.saldoForm1.s12['norek'] = '032901042609406' 
        this.saldoForm1.s12['kd_rek'] = '01' 

        this.saldoForm1.s13['kd_bank'] = '002' // BRI
        this.saldoForm1.s13['norek'] = '032901042656403' 
        this.saldoForm1.s13['kd_rek'] = '01' 

        this.saldoForm1.s14['kd_bank'] = '200' // BTN
        this.saldoForm1.s14['norek'] = '0021101400199458' 
        this.saldoForm1.s14['kd_rek'] = '01' 

        this.saldoForm1.s15['kd_bank'] = '422' // BRI Syariah
        this.saldoForm1.s15['norek'] = '2102800147' 
        this.saldoForm1.s15['kd_rek'] = '01' 

        this.saldoForm1.s16['kd_bank'] = '002' // BNI
        this.saldoForm1.s16['norek'] = '032901004259302' 
        this.saldoForm1.s16['kd_rek'] = '01' 

        //02
        this.saldoForm2.s1['kd_bank'] = '008' // BMRI
        this.saldoForm2.s1['norek'] = '1220007772737' 
        this.saldoForm2.s1['kd_rek'] = '02' 

        this.saldoForm2.s2['kd_bank'] = '002' // BRI
        this.saldoForm2.s2['norek'] = '032901003585302' 
        this.saldoForm2.s2['kd_rek'] = '02' 

        this.saldoForm2.s3['kd_bank'] = '009' // BNI
        this.saldoForm2.s3['norek'] = '2737273727' 
        this.saldoForm2.s3['kd_rek'] = '02' 

        this.saldoForm2.s4['kd_bank'] = '008' // BMRI
        this.saldoForm2.s4['norek'] = '1220087882737' 
        this.saldoForm2.s4['kd_rek'] = '02' 

        //03
        this.saldoForm3.kd_bank = '008'
        this.saldoForm3.kd_rek = '03'
      },
      resetFormLayanan () {
        this.layananForm1= Object.assign({}, layananbluForm)
        this.layananForm2= Object.assign({}, layananbluForm)
        this.layananForm3= Object.assign({}, layananbluForm)
        this.layananForm4= Object.assign({}, layananbluForm)
        this.layananForm5= Object.assign({}, layananbluForm)

        this.model1 = { valid: false, isEdit: false, canSend: true , idData: 0, respon: '', tglUpdate: ''}
        this.model2 = { valid: false, isEdit: false, canSend: true , idData: 0, respon: '', tglUpdate: ''}
        this.model3 = { valid: false, isEdit: false, canSend: true , idData: 0, respon: '', tglUpdate: ''}
        this.model4 = { valid: false, isEdit: false, canSend: true , idData: 0, respon: '', tglUpdate: ''}
        this.model5 = { valid: false, isEdit: false, canSend: true , idData: 0, respon: '', tglUpdate: ''}

        this.layananForm1.kd_indikator = '30001'
        this.layananForm2.kd_indikator = '30002'
        this.layananForm3.kd_indikator = '30003'
        this.layananForm4.kd_indikator = '30004'
        this.layananForm5.kd_indikator = '30005'
      },
      
      async initTrxLaporan() {
        var vm = this
        console.log('initTrxLaporan')
        vm.listLoading = true

        getTrxLaporan(vm.txtDatein).then(response => {
          // console.log('initTrxLaporan', response.data)
          var array = response.data 
          if(array != null){
            array.forEach(element => {
              if(element.type == 'saldoblu'){
                if(element.kode == '01'){
                  vm.modes1.respon = element.responsedata
                  vm.modes1.tglUpdate = moment(element.updated_at).format('YYYY-MM-DD HH:MM:ss')
                  vm.modes1.canSend = false
                }else if(element.kode == '02'){
                  vm.modes2.respon = element.responsedata
                  vm.modes2.tglUpdate = moment(element.updated_at).format('YYYY-MM-DD HH:MM:ss')
                  vm.modes2.canSend = false
                }else if(element.kode == '03'){
                  vm.modes3.respon = element.responsedata
                  vm.modes3.tglUpdate = moment(element.updated_at).format('YYYY-MM-DD HH:MM:ss')
                  vm.modes3.canSend = false
                }
              }else if(element.type == 'layananlain'){
                
                if(element.kode == '30001'){
                  vm.model1.respon = element.responsedata
                  vm.model1.tglUpdate = moment(element.updated_at).format('YYYY-MM-DD HH:MM:ss')
                  vm.model1.canSend = false
                }else if(element.kode == '30002'){
                  vm.layananForm2 = element
                  vm.model2.respon = element.responsedata
                  vm.model2.tglUpdate = moment(element.updated_at).format('YYYY-MM-DD HH:MM:ss')
                  vm.model2.canSend = false
                }else if(element.kode == '30003'){
                  vm.layananForm3 = element
                  vm.model3.respon = element.responsedata
                  vm.model3.tglUpdate = moment(element.updated_at).format('YYYY-MM-DD HH:MM:ss')
                  vm.model3.canSend = false
                }else if(element.kode == '30004'){
                  vm.layananForm4 = element
                  vm.model4.respon = element.responsedata
                  vm.model4.tglUpdate = moment(element.updated_at).format('YYYY-MM-DD HH:MM:ss')
                  vm.model4.canSend = false
                }else if(element.kode == '30005'){
                  vm.layananForm5 = element
                  vm.model5.respon = element.responsedata
                  vm.model5.tglUpdate = moment(element.updated_at).format('YYYY-MM-DD HH:MM:ss')
                  vm.model5.canSend = false
                }
              }
            });
          }
          
          vm.listTrxLog = array
          vm.listLoading = false
        })
      },
      async getListMonitoring(queryInfo) {
        var vm = this
        const { sortBy, sortDesc, page, itemsPerPage } = vm.tableOpt
        vm.listQuery.page = page
        vm.listQuery.limit = itemsPerPage
        vm.listQuery.limit = itemsPerPage == -1 ? vm.totalData : itemsPerPage

        vm.listQuery.search = vm.txtDatein
        // console.log('listQuery: ',vm.listQuery)
        // get total data
        vm.isLoading = true
        countTRXBIOS(vm.listQuery).then(response => {
          console.log('countTRXBIOS', response.data)
          var total = response.data
          vm.totalData = parseInt(total)
          vm.isLoading = false
        })
        // get list of data
        vm.listLoading = true
        getTRXBIOS(vm.listQuery).then(response => {
          console.log('getTRXBIOS', response.data)
          var array = response.data 
          let opts = { format: '%s%v', symbol: 'Rp. ' }

          if(array != null){
            // console.log(array)
            array.forEach(element => {
              element['jsond'] = JSON.parse(element.data)
              element['jsonrd'] = JSON.parse(element.responsedata)
            });
          }
          
          vm.listData = array
          vm.listLoading = false
        })
      },
      async refreshTokenBG2() {
        var vm = this
        vm.listLoading = true
        refreshTokenBIOSG2().then(response => {
          console.log('refreshTokenBIOSG2', response)
          vm.listLoading = false
        })
      },

      async getDataWSBios() {
        var vm = this
        
        console.log('getDataWSBios',vm.txtDatein)
        vm.listLoading = true
        vm.dataWS['penerimaan'] = []
        vm.dataWS['pengeluaran'] = []
        vm.dataWS['saldo'] = []
        vm.dataWS['layanan'] = []

        getPenerimaanFromWSBios(vm.txtDatein).then(response => {
          // console.log('getPenerimaanFromWSBios', response.data)
          var array = response.data
          if(array){
            if(array[0] == 'sessionlog token not found' || array[0].status == "MSG20003"){
              vm.refreshTokenBG2() //Token BIOS G2 refresh
            } else if (array[0].status == "MSG20002"){
              vm.toast = {
                show: true, color:'red', text: array[0].message, timeout: 2000
              }
            } else{
              vm.dataWS['penerimaan'] = array[0].data
            }
          }
          vm.listLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
          console.log('getPenerimaanFromWSBios catch error', err)
        })

        getPengeluaranFromWSBios(vm.txtDatein).then(response => {
          // console.log('getPengeluaranFromWSBios', response.data)
          var array = response.data
          if(array){
            vm.dataWS['pengeluaran'] = array[0].data
          }
          vm.listLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
          console.log('getPengeluaranFromWSBios catch error', err)
        })

        getSaldoFromWSBios(vm.txtDatein).then(response => {
          // console.log('getSaldoFromWSBios', response.data)
          var array = response.data
          if(array){
            vm.dataWS['saldo'] = array[0].data
          }
          vm.listLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
          console.log('getSaldoFromWSBios catch error', err)
        })

        getLayananLainFromWSBios(vm.txtDatein).then(response => {
          // console.log('getLayananLainFromWSBios', response.data)
          var array = response.data
          if(array){
            vm.dataWS['layanan'] = array[0].data
          }
          vm.listLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
          console.log('getLayananLainFromWSBios catch error', err)
        })

        // console.log('dataWS', vm.dataWS)
      },

      async checkSaldoBLU() {
        var vm = this

        vm.resetFormSaldo()
        // get list of data
        vm.listLoading = true
        getSaldoBLU(vm.txtDatein).then(response => {
          console.log('checkSaldoBLU', response.data)
          var array = response.data
          if(array){
            array.forEach(element => {
              delete element.created_at
              delete element.updated_at

              if(element.kd_rek == '01'){
                if(element.norek == '032901004259302'){
                  vm.saldoForm1.s16 = element
                  vm.modes1.s16.isEdit = true
                  vm.modes1.s16.idData = element.id
                } else if(element.norek == '2102800147'){
                  vm.saldoForm1.s15 = element
                  vm.modes1.s15.isEdit = true
                  vm.modes1.s15.idData = element.id
                } else if(element.norek == '0021101400199458'){
                  vm.saldoForm1.s14 = element
                  vm.modes1.s14.isEdit = true
                  vm.modes1.s14.idData = element.id
                } else if(element.norek == '032901042656403'){
                  vm.saldoForm1.s13 = element
                  vm.modes1.s13.isEdit = true
                  vm.modes1.s13.idData = element.id
                } else if(element.norek == '032901042609406'){
                  vm.saldoForm1.s12 = element
                  vm.modes1.s12.isEdit = true
                  vm.modes1.s12.idData = element.id
                }else if(element.norek == '032901042607404'){
                  vm.saldoForm1.s11 = element
                  vm.modes1.s11.isEdit = true
                  vm.modes1.s11.idData = element.id
                }else if(element.norek == '032901042606408'){
                  vm.saldoForm1.s10 = element
                  vm.modes1.s10.isEdit = true
                  vm.modes1.s10.idData = element.id
                }else if(element.norek == '032901040532409'){
                  vm.saldoForm1.s9 = element
                  vm.modes1.s9.isEdit = true
                  vm.modes1.s9.idData = element.id
                }else if(element.norek == '1407202001'){
                  vm.saldoForm1.s1 = element
                  vm.modes1.s1.isEdit = true
                  vm.modes1.s1.idData = element.id
                }else if(element.norek == '0979293061'){
                  vm.saldoForm1.s2 = element
                  vm.modes1.s2.isEdit = true
                  vm.modes1.s2.idData = element.id
                }else if(element.norek == '0986312859'){
                  vm.saldoForm1.s3 = element
                  vm.modes1.s3.isEdit = true
                  vm.modes1.s3.idData = element.id
                }else if(element.norek == '1043581215'){
                  vm.saldoForm1.s4 = element
                  vm.modes1.s4.isEdit = true
                  vm.modes1.s4.idData = element.id
                }else if(element.norek == '1044325410'){
                  vm.saldoForm1.s5 = element
                  vm.modes1.s5.isEdit = true
                  vm.modes1.s5.idData = element.id
                }else if(element.norek == '2508202087'){
                  vm.saldoForm1.s6 = element
                  vm.modes1.s6.isEdit = true
                  vm.modes1.s6.idData = element.id
                }else if(element.norek == '2608202006'){
                  vm.saldoForm1.s7 = element
                  vm.modes1.s7.isEdit = true
                  vm.modes1.s7.idData = element.id
                }else if(element.norek == '1220205471421'){
                  vm.saldoForm1.s8 = element
                  vm.modes1.s8.isEdit = true
                  vm.modes1.s8.idData = element.id
                }
 
              }else if(element.kd_rek == '02'){
                if(element.norek == '1220007772737'){
                  vm.saldoForm2.s1 = element
                  vm.modes2.s1.isEdit = true
                  vm.modes2.s1.idData = element.id
                } else if(element.norek == '032901003585302'){
                  vm.saldoForm2.s2 = element
                  vm.modes2.s2.isEdit = true
                  vm.modes2.s2.idData = element.id
                } else if(element.norek == '2737273727'){
                  vm.saldoForm2.s3 = element
                  vm.modes2.s3.isEdit = true
                  vm.modes2.s3.idData = element.id
                } else if(element.norek == '1220087882737'){
                  vm.saldoForm2.s4 = element
                  vm.modes2.s4.isEdit = true
                  vm.modes2.s4.idData = element.id
                }

              }else if(element.kd_rek == '03'){
                vm.saldoForm3 = element
                vm.modes3.isEdit = true
                vm.modes3.idData = element.id
              }
            });
          }

          vm.listLoading = false
        })
      },
      async checkLayananLain() {
        var vm = this
        vm.resetFormLayanan()

        // get list of data
        vm.listLoading = true
        getLayananLain(vm.txtDatein).then(response => {
          console.log('checkLayananLain', response.data)
          var array = response.data
          if(array){
            array.forEach(element => {
              delete element.created_at
              delete element.updated_at

              if(element.kd_indikator == '30001'){
                vm.layananForm1 = element
                vm.model1.isEdit = true
                vm.model1.idData = element.id
              }else if(element.kd_indikator == '30002'){
                vm.layananForm2 = element
                vm.model2.isEdit = true
                vm.model2.idData = element.id
              }else if(element.kd_indikator == '30003'){
                vm.layananForm3 = element
                vm.model3.isEdit = true
                vm.model3.idData = element.id
              }else if(element.kd_indikator == '30004'){
                vm.layananForm4 = element
                vm.model4.isEdit = true
                vm.model4.idData = element.id
              }else if(element.kd_indikator == '30005'){
                vm.layananForm5 = element
                vm.model5.isEdit = true
                vm.model5.idData = element.id
              }
            })
          }
        })

      },
      async initKodeBank() {
        var vm = this
        // get list of data
        vm.listLoading = true
        getKodeBank().then(response => {
          // console.log('getKodeBank', response.data)
          var array = response.data.data
          
          vm.listKodeBank = array
          vm.listLoading = false
        })
      },
      async initKodeIndikator() {
        var vm = this
        // get list of data
        vm.listLoading = true
        getKodeIndikator().then(response => {
          // console.log('getKodeIndikator', response.data)
          var array = response.data.data
          
          vm.listKodeIndikator = array
          vm.listLoading = false
        })
      },
      async saveAddFormS(tipe, sdata){
        var vm = this
        var formData = null
        var idDB = 0
        var isEdit = false

        if(tipe == 1){
          if(sdata == 1){
            formData = vm.saldoForm1.s1
            idDB = vm.modes1.s1.idData
            isEdit = vm.modes1.s1.isEdit
          } else if(sdata == 2){
            formData = vm.saldoForm1.s2
            idDB = vm.modes1.s2.idData
            isEdit = vm.modes1.s2.isEdit
          } else if(sdata == 3){
            formData = vm.saldoForm1.s3
            idDB = vm.modes1.s3.idData
            isEdit = vm.modes1.s3.isEdit
          } else if(sdata == 4){
            formData = vm.saldoForm1.s4
            idDB = vm.modes1.s4.idData
            isEdit = vm.modes1.s4.isEdit
          } else if(sdata == 5){
            formData = vm.saldoForm1.s5
            idDB = vm.modes1.s5.idData
            isEdit = vm.modes1.s5.isEdit
          } else if(sdata == 6){
            formData = vm.saldoForm1.s6
            idDB = vm.modes1.s6.idData
            isEdit = vm.modes1.s6.isEdit
          } else if(sdata == 7){
            formData = vm.saldoForm1.s7
            idDB = vm.modes1.s7.idData
            isEdit = vm.modes1.s7.isEdit
          } else if(sdata == 8){
            formData = vm.saldoForm1.s8
            idDB = vm.modes1.s8.idData
            isEdit = vm.modes1.s8.isEdit
          }else if(sdata == 9){
            formData = vm.saldoForm1.s9
            idDB = vm.modes1.s9.idData
            isEdit = vm.modes1.s9.isEdit
          } else if(sdata == 10){
            formData = vm.saldoForm1.s10
            idDB = vm.modes1.s10.idData
            isEdit = vm.modes1.s10.isEdit
          } else if(sdata == 11){
            formData = vm.saldoForm1.s11
            idDB = vm.modes1.s11.idData
            isEdit = vm.modes1.s11.isEdit
          } else if(sdata == 12){
            formData = vm.saldoForm1.s12
            idDB = vm.modes1.s12.idData
            isEdit = vm.modes1.s12.isEdit
          } else if(sdata == 13){
            formData = vm.saldoForm1.s13
            idDB = vm.modes1.s13.idData
            isEdit = vm.modes1.s13.isEdit
          } else if(sdata == 14){
            formData = vm.saldoForm1.s14
            idDB = vm.modes1.s14.idData
            isEdit = vm.modes1.s14.isEdit
          } else if(sdata == 15){
            formData = vm.saldoForm1.s15
            idDB = vm.modes1.s15.idData
            isEdit = vm.modes1.s15.isEdit
          } else if(sdata == 16){
            formData = vm.saldoForm1.s16
            idDB = vm.modes1.s16.idData
            isEdit = vm.modes1.s16.isEdit
          }
          
        } else if(tipe == 2){
          if(sdata == 1){
            formData = vm.saldoForm2.s1
            idDB = vm.modes2.s1.idData
            isEdit = vm.modes2.s1.isEdit
          } else if(sdata == 2){
            formData = vm.saldoForm2.s2
            idDB = vm.modes2.s2.idData
            isEdit = vm.modes2.s2.isEdit
          } else if(sdata == 3){
            formData = vm.saldoForm2.s3
            idDB = vm.modes2.s3.idData
            isEdit = vm.modes2.s3.isEdit
          } else if(sdata == 4){
            formData = vm.saldoForm2.s4
            idDB = vm.modes2.s4.idData
            isEdit = vm.modes2.s4.isEdit
          }

        } else if(tipe == 3){
          formData = vm.saldoForm3
          idDB = vm.modes3.idData
          isEdit = vm.modes3.isEdit
        }else{
          return false
        }

        formData['tgl_transaksi'] = vm.txtDatein
        // console.log('saveAddFormS', formData)

        vm.$confirm("Apakah data yang di input sudah benar?").then(() => {
          // get list of data
          if(!isEdit){
            vm.listLoading = true
            insertSaldoBLU(formData).then(response => {
              // console.log('insertSaldoBLU', response.data)
      
              vm.listLoading = false
              vm.toast = {
                show: true, color:'green', text: 'Submit Data BERHASIL.', timeout: 2000
              }
              if(tipe == 1){
                
                if(sdata == 1){
                  vm.modes1.s1.valid = false
                } else if(sdata == 2){
                  vm.modes1.s2.valid = false
                } else if(sdata == 3){
                  vm.modes1.s3.valid = false
                } else if(sdata == 4){
                  vm.modes1.s4.valid = false
                } else if(sdata == 5){
                  vm.modes1.s5.valid = false
                } else if(sdata == 6){
                  vm.modes1.s6.valid = false
                } else if(sdata == 7){
                  vm.modes1.s7.valid = false
                } else if(sdata == 8){
                  vm.modes1.s8.valid = false
                }else if(sdata == 9){
                  vm.modes1.s9.valid = false
                } else if(sdata == 10){
                  vm.modes1.s10.valid = false
                } else if(sdata == 11){
                  vm.modes1.s11.valid = false
                } else if(sdata == 12){
                  vm.modes1.s12.valid = false
                } else if(sdata == 13){
                  vm.modes1.s13.valid = false
                } else if(sdata == 14){
                  vm.modes1.s14.valid = false
                } else if(sdata == 15){
                  vm.modes1.s15.valid = false
                } else if(sdata == 16){
                  vm.modes1.s16.valid = false
                }
              } else if(tipe == 2){
                if(sdata == 1){
                  vm.modes2.s1.valid = false
                } else if(sdata == 2){
                  vm.modes2.s2.valid = false
                } else if(sdata == 3){
                  vm.modes2.s3.valid = false
                } else if(sdata == 4){
                  vm.modes2.s4.valid = false
                }
              } else if(tipe == 3){
                vm.modes3.valid = false
              } 

              vm.checkSaldoBLU()
            }).catch(err => {
              // console.log(err)
              vm.isLoading = false
              vm.toast = {
                show: true, color:'red', text: 'Submit Data GAGAL, silakan dicoba kembali.', timeout: 2000
              }
            })
          }else{
            if(idDB == 0) return false
            vm.listLoading = true
            updateSaldoBLU(formData, idDB).then(response => {
              // console.log('updateSaldoBLU', response.data)
      
              vm.listLoading = false
              vm.toast = {
                show: true, color:'green', text: 'Ubah Data BERHASIL.', timeout: 2000
              }
              if(tipe == 1){
                vm.modes1.valid = false
              } else if(tipe == 2){
                vm.modes2.valid = false
              } else if(tipe == 3){
                vm.modes3.valid = false
              } 

              vm.checkSaldoBLU()
            }).catch(err => {
              // console.log(err)
              vm.isLoading = false
              vm.toast = {
                show: true, color:'red', text: 'Ubah Data GAGAL, silakan dicoba kembali.', timeout: 2000
              }
            })
          }
        });
        
      },
      async saveAddFormL(tipe){
        var vm = this
        var formData = null
        var idDB = 0
        var isEdit = false
        if(tipe == 1){
          formData = vm.layananForm1
          idDB = vm.model1.idData
          isEdit = vm.model1.isEdit
        } else if(tipe == 2){
          formData = vm.layananForm2
          idDB = vm.model2.idData
          isEdit = vm.model2.isEdit
        } else if(tipe == 3){
          formData = vm.layananForm3
          idDB = vm.model3.idData
          isEdit = vm.model3.isEdit
        } else if(tipe == 4){
          formData = vm.layananForm4
          idDB = vm.model4.idData
          isEdit = vm.model4.isEdit
        } else if(tipe == 5){
          formData = vm.layananForm5
          idDB = vm.model5.idData
          isEdit = vm.model5.isEdit
        }else {
          return false
        }

        formData['tgl_transaksi'] = vm.txtDatein
        // console.log('saveAddFormL', formData)

        vm.$confirm("Apakah data yang di input sudah benar?").then(() => {
          //do something...
          if(!isEdit){
          vm.listLoading = true
            insertLayananLain(formData).then(response => {
              // console.log('insertLayananLain', response.data)
      
              vm.listLoading = false
              vm.toast = {
                show: true, color:'green', text: 'Submit Data BERHASIL.', timeout: 2000
              }
              if(tipe == 1){
                vm.model1.valid = false
              } else if(tipe == 2){
                vm.model2.valid = false
              } else if(tipe == 3){
                vm.model3.valid = false
              } else if(tipe == 4){
                vm.model4.valid = false
              } else if(tipe == 5){
                vm.model5.valid = false
              }
              vm.checkLayananLain()
            }).catch(err => {
              // console.log(err)
              vm.isLoading = false
              vm.toast = {
                show: true, color:'red', text: 'Submit Data GAGAL, silakan dicoba kembali.', timeout: 2000
              }
            })
          }else{
            if(idDB == 0) return false
            vm.listLoading = true
            updateLayananLain(formData, idDB).then(response => {
              // console.log('updateLayananLain', response.data)
      
              vm.listLoading = false
              vm.toast = {
                show: true, color:'green', text: 'Ubah Data BERHASIL.', timeout: 2000
              }
              if(tipe == 1){
                vm.model1.valid = false
              } else if(tipe == 2){
                vm.model2.valid = false
              } else if(tipe == 3){
                vm.model3.valid = false
              } else if(tipe == 4){
                vm.model4.valid = false
              } else if(tipe == 5){
                vm.model5.valid = false
              }
              vm.checkLayananLain()
            })
            .catch(err => {
              // console.log(err)
              vm.isLoading = false
              vm.toast = {
                show: true, color:'red', text: 'Ubah Data GAGAL, silakan dicoba kembali.', timeout: 2000
              }
            })
          }
        });

      },
      async doSendSaldo(tipe){
        var vm = this
        var formData = null

        if(tipe == 1){
          formData = vm.saldoForm1
        } else if(tipe == 2){
          formData = vm.saldoForm2
        } else if(tipe == 3){
          formData = vm.saldoForm3
        }else{
          return false
        }

        if(formData.saldo == 0 || formData.norek == ''){
          vm.toast = {
            show: true, color:'red', text: 'Saldo tidak boleh 0 atau No Rekening tidak boleh kosong! ', timeout: 2000
          }
          return false
        }

        formData['tgl_transaksi'] = vm.txtDatein
        // console.log('doSendSaldo', formData)

        vm.$confirm("Apakah anda yakin untuk mengirimkan Data Saldo BLU tersebut?").then(() => {
          vm.listLoading = true
          sendSaldoBLU(formData).then(response => {
            // console.log('sendSaldoBLU', response.data)
    
            vm.listLoading = false
            vm.toast = {
              show: true, color:'green', text: 'Submit Data BERHASIL.', timeout: 2000
            }
            setTimeout(() => location.reload(), 1000)
          }).catch(err => {
            // console.log(err)
            vm.isLoading = false
            vm.toast = {
              show: true, color:'red', text: 'Submit Data GAGAL, silakan dicoba kembali.', timeout: 2000
            }
          })
        });
       

      },
      async doSendLayanan(tipe){
        var vm = this
        var formData = null

        if(tipe == 1){
          formData = vm.layananForm1
        } else if(tipe == 2){
          formData = vm.layananForm2
        } else if(tipe == 3){
          formData = vm.layananForm3
        } else if(tipe == 4){
          formData = vm.layananForm4
        } else if(tipe == 5){
          formData = vm.layananForm5
        }else {
          return false
        }

        if(formData.jumlah == 0){
          vm.toast = {
            show: true, color:'red', text: 'Saldo tidak boleh 0', timeout: 2000
          }
          return false
        }

        formData['tgl_transaksi'] = vm.txtDatein
        // console.log('doSendSaldo', formData)

        vm.$confirm("Apakah anda yakin untuk mengirimkan Data Layanan Lainnya tersebut?").then(() => {
          vm.listLoading = true
          sendLayananLain(formData).then(response => {
            // console.log('sendLayananLain', response.data)
    
            vm.listLoading = false
            vm.toast = {
              show: true, color:'green', text: 'Submit Data BERHASIL.', timeout: 2000
            }
            setTimeout(() => location.reload(), 1000)
          }).catch(err => {
            // console.log(err)
            vm.isLoading = false
            vm.toast = {
              show: true, color:'red', text: 'Submit Data GAGAL, silakan dicoba kembali.', timeout: 2000
            }
          })
          
        });

      }

    }
  }
</script>
