import request from '../utils/request'
import { _GLOBAL } from './server'
import secureStorage from '@/utils/secureStorage'

export function getListBIOSByDate (dateIN, dateOUT) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

 var uri = _GLOBAL.URLBACKEND + '/cusqueries/bios/get_report?startPeriod=' + dateIN + '&endPeriod=' + dateOUT
// console.log(uri)

  var response = request({
    url: uri,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    }
  })
  // console.log(response)
  return response
}

export function getTRXBIOS(listQuery) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  console.log(listQuery)
  var page = listQuery.page
  var limit = listQuery.limit
  var tgl = listQuery.search
  // console.log('limit', limit)

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBIOS + '/trxlogs?tgl=' + tgl + '&_sort=id%3ADESC'
  console.log(uri)
  
  var data = listQuery
  console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countTRXBIOS(listQuery) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var tgl = listQuery.search
  var data = ''
  
  var uri = _GLOBAL.URLBIOS + '/trxlogs/count?tgl=' + tgl
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getKodeIndikator() {

  var uri = _GLOBAL.URLBIOS + '/sessionlogs/ref/indikator'
  var response = request({
    url: uri,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    }
  })
  // console.log(response)
  return response
}

export function getKodeBank() {

  var uri = _GLOBAL.URLBIOS + '/sessionlogs/ref/bank'
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    }
  })
  // console.log(response)
  return response
}

export function getTrxLaporan(tgl) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBIOS + '/trxlogs?tgl='+tgl
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    }
  })
  // console.log(response)
  return response
}

export function getSaldoBLU(tgl) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBIOS + '/saldoblus?tgl_transaksi='+tgl
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    }
  })
  // console.log(response)
  return response
}

export function insertSaldoBLU (data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBIOS + '/saldoblus'
  // console.log(uri)

  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function deleteSaldoBLU (id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBIOS + '/saldoblus/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateSaldoBLU (data, id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBIOS + '/saldoblus/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getLayananLain(tgl) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBIOS + '/layananlains?tgl_transaksi='+tgl
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    }
  })
  // console.log(response)
  return response
}

export function insertLayananLain (data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBIOS + '/layananlains'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function deleteLayananLain (id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBIOS + '/layananlains/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateLayananLain (data, id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBIOS + '/layananlains/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function sendPenerimaan (data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBIOS + '/trxlogs/bioshit/penerimaan'
  // console.log(uri)

  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function sendBeban (data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBIOS + '/trxlogs/bioshit/beban'
  // console.log(uri)

  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function sendSaldoBLU (data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBIOS + '/trxlogs/bioshit/saldoblu'
  // console.log(uri)

  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function sendLayananLain (data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBIOS + '/trxlogs/bioshit/layananlain'
  // console.log(uri)

  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function refreshTokenBIOSG2() {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var secretKeyBIOSG2 = 'btRfk3YyqKlL8eiuDVubEnrtoBlr3TWw'
  var uri = _GLOBAL.URLBIOS + '/sessionlogs/biosg2/auth/' + secretKeyBIOSG2
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    }
  })
  // console.log(response)
  return response
}



export function getPenerimaanFromWSBios(tgl) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBIOS + '/sessionlogs/wsdata/penerimaan/'+tgl
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    }
  })
  // console.log(response)
  return response
}

export function getPengeluaranFromWSBios(tgl) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBIOS + '/sessionlogs/wsdata/pengeluaran/'+tgl
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    }
  })
  // console.log(response)
  return response
}

export function getSaldoFromWSBios(tgl) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBIOS + '/sessionlogs/wsdata/saldo/'+tgl
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    }
  })
  // console.log(response)
  return response
}

export function getLayananLainFromWSBios(tgl) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBIOS + '/sessionlogs/wsdata/lainnya/'+tgl
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    }
  })
  // console.log(response)
  return response
}
